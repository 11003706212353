var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Admin Utility")]), _c('p', [_vm._v("Sync, reusable seeders, cache and MISC")]), _c('h3', {
    staticClass: "mt-4 mb-2"
  }, [_vm._v(" Cache Clear ")]), _c('div', [_vm._v("When you suspect the distributor results got cached, e.g. product list, category / brand list")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.useUtility('cache-clear');
      }
    }
  }, [_vm._v(" Clear ")]), _c('h3', {
    staticClass: "mt-4 mb-2"
  }, [_vm._v(" Sync Core (for a specific distributor) ")]), _vm._m(0), _c('div', {
    staticClass: "d-flex mt-4"
  }, [_c('v-autocomplete', {
    staticStyle: {
      "max-width": "200px"
    },
    attrs: {
      "label": "Distributor",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "items": _vm.distributorOptions
    },
    model: {
      value: _vm.distributor_id,
      callback: function callback($$v) {
        _vm.distributor_id = $$v;
      },
      expression: "distributor_id"
    }
  }), _c('v-btn', {
    staticClass: "ml-4",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.useUtility('sync-core-user', {
          distributor_id: _vm.distributor_id
        });
      }
    }
  }, [_vm._v(" Sync ")])], 1), _c('h3', {
    staticClass: "mt-4 mb-2"
  }, [_vm._v(" Seed Site Section Items ")]), _c('div', [_vm._v(" When Backend added new items / sections with reserved keys. ")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.useUtility('seed-site');
      }
    }
  }, [_vm._v(" Seed Items ")]), _c('h3', {
    staticClass: "mt-4 mb-2"
  }, [_vm._v(" Seed Site Setting ")]), _c('div', [_vm._v(" When Backend added new site setting keys ")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.useUtility('seed-site-setting');
      }
    }
  }, [_vm._v(" Seed Site Setting ")]), _c('h3', {
    staticClass: "mt-4 mb-2"
  }, [_vm._v(" Seed Dictionary + Sync to Core ")]), _c('div', [_vm._v(" When Backend added new dictionary reserved keys and Sync to Core. ")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.useUtility('seed-dictionary');
      }
    }
  }, [_vm._v(" Seed Dictionary ")]), _c('h3', {
    staticClass: "mt-4 mb-2"
  }, [_vm._v(" Seed Country from core ")]), _c('div', [_vm._v(" When core added a new country (e.g. China) on this deployment. ")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.useUtility('seed-country');
      }
    }
  }, [_vm._v(" Seed Country ")]), _c('h3', {
    staticClass: "mt-4 mb-2"
  }, [_vm._v(" Seed Feature ")]), _c('div', [_vm._v(" When Backend added new modules/features ")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.useUtility('seed-feature');
      }
    }
  }, [_vm._v(" Seed Feature ")]), _c('h3', {
    staticClass: "mt-4 mb-2"
  }, [_vm._v(" Migrate a distributor ")]), _c('a', {
    attrs: {
      "href": "https://mixcare-health.atlassian.net/wiki/spaces/MA/pages/229441537",
      "target": "_blank"
    }
  }, [_vm._v("See Migration Docs")]), _vm._v(" for detailed steps. You are able to: "), _vm._m(1), _c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "loading": _vm.loading,
      "to": "/distributor/migration-scheme-list"
    }
  }, [_vm._v(" Go to Migration Page ")])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._v(" When you think some distributors users in backend & core are unsync (e.g. core flushed in dev) "), _c('br'), _vm._v("* Each Distributor is handled by jobs separated per 100 users. It takes ~3 seconds per 100 users. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_vm._v("Move distributors to a "), _c('u', [_vm._v("new channel.")])]), _c('li', [_vm._v("Upgrade a distributor to a "), _c('u', [_vm._v("new channel.")])])]);
}]

export { render, staticRenderFns }